"use client";

import React, { FC, useState } from "react";
import { StaySearchFormFields } from "../type";
import StaySearchForm from "./(product-search-form)/StaySearchForm";
import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
import RentalCarSearchForm from "./(event-search-form)/StaySearchForm";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";

export type SearchTab =
  | "Activités aériennes"
  | "Événements"
  | "Vols privés"
  | "Transferts";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  onTabChange?: (tab: SearchTab) => void;
  currentPage?:
    | "Activités aériennes"
    | "Événements"
    | "Vols privés"
    | "Transferts";
  defaultFieldFocus?: StaySearchFormFields;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Activités aériennes",
  currentPage,
  defaultFieldFocus,
  onTabChange,
}) => {
  const tabs: SearchTab[] = [
    "Activités aériennes",
    "Événements",
    "Vols privés",
    "Transferts",
  ];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderTab = () => {
    return (
      <ul className="h-[88px] flex justify-center space-x-5 sm:space-x-9">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => {
                setTabActive(tab);
                onTabChange && onTabChange(tab);
              }}
              className={`relative flex-shrink-0 flex items-center cursor-pointer text-base ${
                active
                  ? "text-neutral-900 dark:text-neutral-200 font-medium"
                  : "text-neutral-500 dark:text-neutral-300 "
              } `}
              key={tab}
            >
              <div className="relative select-none">
                <span>{tab}</span>
                {active && (
                  <span className="absolute top-full mt-1 block w-full h-0.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "Activités aériennes":
        return <StaySearchForm />;
      case "Événements":
        return <RentalCarSearchForm />;
      // case "Vols privés":
      //   return <FlightSearchForm />;
      // case "Transferts":
      //   return <ExperiencesSearchForm />;

      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchFormSmall ${className}`}
      data-nc-id="HeroSearchFormSmall"
    >
      {renderTab()}
      <div className="mt-2">{renderForm()}</div>
    </div>
  );
};

export default HeroSearchForm;

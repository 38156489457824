import React from "react";
import Image from "next/image";
import logoImg from "@/images/logo.png";
import logoLightImg from "@/images/logo-light.png";
import logoRight from "@/images/logo-right.png"; // Chemin de l'image que tu m'as fournie
import Link from "next/link";
import { StaticImageData } from "next/image";

export interface LogoProps {
  img?: StaticImageData;
  imgLight?: StaticImageData;
  logoRightImg?: StaticImageData;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  logoRightImg = logoRight,
  className = "w-24",
}) => {
  return (
    <Link
      href="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
      aria-label="Retour à la page d'accueil"
    >
      <div className="flex items-center space-x-2"> {/* Flexbox container pour aligner les éléments */}
        {/* Affichage du logo principal */}
        {img && (
          <Image
            className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
            src={img} // Utilisation de next/image avec src
            alt="Logo Esifly"
            height={48} // Hauteur pour contrôler la taille
            priority // Charger l'image en priorité
          />
        )}
        {/* Affichage du logo en mode "dark" */}
        {imgLight && (
          <Image
            className="hidden max-h-12 dark:block"
            src={imgLight} // Utilisation de next/image avec src
            alt="Logo-Light Esifly"
            height={48}
          />
        )}
        {/* Ajout de l'image à droite */}
        <Image
          className="inline-block max-h-8 ml-4 align-middle" // Ajustement de la taille et espacement
          src={logoRightImg} // Utilisation de next/image pour le logo de droite
          alt="Logo Esifly Right"
          height={32}
        />
      </div>
    </Link>
  );
};

export default Logo;

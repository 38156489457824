"use client";

import React, { Fragment, useState, useEffect, useCallback, useMemo } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import MobileStaySearchForm from "./(product-search-form)/StaySearchForm";
import RentalCarSearchForm from "./(car-search-form)/RentalCarSearchForm";
import { SearchFormProvider } from "../(HeroSearchForm)/SearchFormContext";
import { Route } from "@/routers/types";
import { useSearchParams, usePathname, useRouter } from 'next/navigation';
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";

export type SearchTab =
  | "Activités aériennes"
  | "Événements"
  | "Vols privés"
  | "Transferts";

const HeroSearchForm2Mobile = () => {
  const pathname = usePathname();
  const router = useRouter();
  const isHomePage = pathname === "/";
  const searchParams = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [tabActive, setTabActive] = useState<SearchTab>("Activités aériennes");
  const [isMobile, setIsMobile] = useState(false);

  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);

  // const tabs: SearchTab[] = ["Activités aériennes", "Événements", "Vols privés", "Transferts"];
  const tabs: SearchTab[] = useMemo(() => ["Activités aériennes", "Événements", "Vols privés", "Transferts"], []);

   // Déplacer useEffect au niveau supérieur
   useEffect(() => {
    const handleNavigate = () => {
      const currentSearchParams = new URLSearchParams(window.location.search);
      if (currentSearchParams.get('modal') === 'PHOTO_TOUR_SCROLLABLE') {
        router.push('/');
      }
    };

    window.addEventListener('popstate', handleNavigate);
    return () => window.removeEventListener('popstate', handleNavigate);
  }, [router]); // Supprimer router de la dépendance

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    const cat = searchParams.get("cat");
    if (cat && tabs.includes(cat as SearchTab)) {
      setTabActive(cat as SearchTab);
    }

    return () => window.removeEventListener("resize", checkScreenSize);
  }, [searchParams, tabs]);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleIconClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!isHomePage) {
      e.stopPropagation();
      router.back();
    }
  }, [isHomePage, router]);

  const renderButtonOpenModal = useCallback(() => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
      >
        <div
          onClick={handleIconClick}
          className="flex-shrink-0 w-5 h-5 cursor-pointer"
        >
          {isHomePage ? (
            <MagnifyingGlassIcon className="w-full h-full" />
          ) : (
            <ArrowUturnLeftIcon className="w-full h-full" />
          )}
        </div>
        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">
            {searchParams.get("cat") === "Événements" ? "Lieu de votre salle de mariage ?" : "N'importe où"}
          </span>
          <span className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400">
            <span className="line-clamp-1">
              N'importe où • N'importe quand • Ajouter des passagers
            </span>
          </span>
        </div>
        <span className="absolute right-2 top-1/2 flex h-9 w-9 -translate-y-1/2 transform items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
					<svg
						viewBox="0 0 16 16"
						aria-hidden="true"
						role="presentation"
						focusable="false"
						className="block h-4 w-4"
						fill="currentColor"
					>
						<path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
					</svg>
				</span>
      </button>
    );
  }, [isHomePage, handleIconClick, searchParams]);

  const getFirstWord = (sentence: string): string =>
    sentence.trim().split(/\s+/)[0] ?? "";

  const renderTab = () => {
    return (
      <Tab.List className="flex w-full justify-center space-x-6 text-sm font-semibold text-neutral-500 dark:text-neutral-400 sm:space-x-8 sm:text-base">
        {tabs.map((tab) => (
          <Tab key={tab} as={Fragment}>
            {({ selected }) => (
              <div
                className={`relative select-none outline-none focus:outline-none focus-visible:ring-0 ${
                  tabActive === tab ? "text-black dark:text-white" : ""
                }`}
                onClick={() => {
                  setTabActive(tab);
                  // Mise à jour de l'URL avec le nouveau paramètre 'cat'
                  const newSearchParams = new URLSearchParams(
                    searchParams.toString()
                  );
                  newSearchParams.set("cat", tab);
                  // window.history.pushState(null, '', `?${newSearchParams.toString()}`);
                }}
              >
                {getFirstWord(tab)}
                {tabActive === tab && (
                  <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                )}
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "Activités aériennes":
        return <MobileStaySearchForm />;
      case "Événements":
        return <RentalCarSearchForm />;
      case "Vols privés":
        return <FlightSearchForm />;
      // case "Transferts":
      //   return <MobileStaySearchForm />;
      default:
        return null;
    }
  };

  const getUrl = () => {
    switch (tabActive) {
      case "Activités aériennes":
        return "/listing-product-map";
      case "Événements":
        return "/listing-flights";
      case "Vols privés":
        return "/listing-flights";
      // case "Transferts":
      //   return <MobileStaySearchForm />;
      default:
        return "";
    }
  };

  return (
    <SearchFormProvider>
      <div className="HeroSearchForm2Mobile">
        {renderButtonOpenModal()}
        <Transition appear show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="HeroSearchFormMobile__Dialog relative z-max"
            onClose={closeModal}
          >
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out transition-transform"
                  enterFrom="opacity-0 translate-y-52"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in transition-transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-52"
                >
                  <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                    {showDialog && (
                      <Tab.Group manual>
                        <div className="absolute left-4 top-4">
                          <button className="" onClick={closeModal}>
                            <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                          </button>
                        </div>

                        <div className="flex-1 flex flex-col pt-12 px-1.5 sm:px-4 overflow-auto">
                          {renderTab()}
                          <div className="flex-1 overflow-y-auto py-4">
                            {renderForm()}
                          </div>
                        </div>

                        <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                          <button
                            type="button"
                            className="underline font-semibold flex-shrink-0"
                            onClick={() => {
                              setShowDialog(false);
                              resetIsShowingDialog();
                            }}
                          >
                            Tout effacer
                          </button>
                          <ButtonSubmit
                            onClick={closeModal}
                            href={getUrl() as Route}
                          />
                        </div>
                      </Tab.Group>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </SearchFormProvider>
  );
};

export default HeroSearchForm2Mobile;

"use client";

import React, { useState, useEffect } from "react";
import NcInputNumber from "@/components/NcInputNumber";
import { FC } from "react";
import { useSearchForm } from "../(HeroSearchForm)/SearchFormContext";

export interface GuestsObject {
  adults: number;
  children: number;
  infants: number;
}

export interface GuestsInputProps {
  className?: string;
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
}

const GuestsInput: FC<GuestsInputProps> = ({
  className = "",
  defaultValue = { adults: 2, children: 0, infants: 0 },
  onChange
}) => {
  const { totalGuests, setTotalGuests } = useSearchForm();
  const [guestAdults, setGuestAdults] = useState(defaultValue.adults);
  const [guestChildren, setGuestChildren] = useState(defaultValue.children);
  const [guestInfants, setGuestInfants] = useState(defaultValue.infants);

  useEffect(() => {
    const newTotal = guestAdults + guestChildren + guestInfants;
    setTotalGuests(newTotal);
    onChange?.({ adults: guestAdults, children: guestChildren, infants: guestInfants });
  }, [guestAdults, guestChildren, guestInfants, setTotalGuests, onChange]);

  const handleChangeAdults = (value: number) => {
    setGuestAdults(value);
  };

  const handleChangeChildren = (value: number) => {
    setGuestChildren(value);
  };

  const handleChangeInfants = (value: number) => {
    setGuestInfants(value);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        Passagers
      </span>
      <span className="mb-5 block text-neutral-500 dark:text-neutral-400">
        {totalGuests} {totalGuests > 1 ? "passagers" : "passager"}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdults}
        onChange={handleChangeAdults}
        max={10}
        min={1}
        label="Adultes"
        desc="13 ans et plus"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestChildren}
        onChange={handleChangeChildren}
        max={4}
        label="Enfants"
        desc="2 à 12 ans"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestInfants}
        onChange={handleChangeInfants}
        max={4}
        label="Bébés"
        desc="0 à 2 ans"
      />
    </div>
  );
};

export default GuestsInput;
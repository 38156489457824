"use client";

import React, { Fragment, useContext, useState } from "react";
import ButtonClose from "@/shared/ButtonClose";
import Logo from "@/shared/Logo";
import { Disclosure, Dialog, Transition } from "@headlessui/react";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "@/data/navigation";
import ButtonPrimary from "@/shared/ButtonPrimary";
import SocialsList from "@/shared/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "@/shared/SwitchDarkMode";
import Link from "next/link";
import LangDropdown from "@/app/(client-components)/(Header)/LangDropdown";
import { AuthContext } from "@/context/AuthProvider";
import { useRouter } from "next/navigation";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO,
  onClickClose,
}) => {
  const authContext = useContext(AuthContext);
  const router = useRouter();
  const isAuthenticated = authContext?.user;

  // State pour gérer l'affichage du modal de confirmation
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

  // Fonctions pour ouvrir/fermer le modal
  const openModalConfirm = () => setIsOpenModalConfirm(true);
  const closeModalConfirm = () => setIsOpenModalConfirm(false);

  const handleMenuItemClick = (item: NavItemType) => {
    if (item.name === "Supprimer mon compte") {
      openModalConfirm();
    }
  };

  const handleDeleteAccount = () => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);

      fetch("https://esi-fly.com/api/delete/index.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          picture: user.picture || "https://esifly.fr/avatar.png",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            console.log("Compte supprimé avec succès");
            // Déconnexion de l'utilisateur et redirection vers la page d'accueil
            handleLogout();
            closeModalConfirm();
          } else {
            console.error("Erreur lors de la suppression du compte :", data.message);
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la requête de suppression :", error);
        });
    }
  };

  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <Link
              href={i.href || ""}
              className="flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5"
            >
              <span
                className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex-1 flex"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="py-2.5 flex justify-end flex-1"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </Link>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <Link
          className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          href={item.href || ""}
        >
          <span
            className={`py-2.5 pr-3 ${!item.children ? "block w-full" : ""}`}
            onClick={() => handleMenuItemClick(item)}
          >
            {item.name}
          </span>
          {item.children && (
            <span className="flex-1 flex" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button
                as="span"
                className="py-2.5 flex items-center justify-end flex-1"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </Link>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  const handleLogout = async () => {
    if (authContext && authContext.logout) {
      await authContext.logout();
      router.push("/login");
      onClickClose && onClickClose();
    }
  };

  return (
    <>
      <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
        <div className="py-6 px-5">
          <Logo />
          <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
            <span>
              Explorez les expériences les plus palpitantes en aviation.
              Racontez vos aventures et partagez-les avec nous.
            </span>

            <div className="flex justify-between items-center mt-4">
              <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
              <span className="block">
                <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
              </span>
            </div>
          </div>
          <span className="absolute right-2 top-2 p-1">
            <ButtonClose onClick={onClickClose} />
          </span>
        </div>
        <ul className="flex flex-col py-6 px-2 space-y-1">
          {data.map(_renderItem)}

          {/* Affichage conditionnel de "Supprimer mon compte" */}
          {isAuthenticated && (
            <li>
              <button
                className="w-full text-left px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-50 dark:text-red-500 dark:hover:bg-neutral-800 rounded-lg"
                onClick={openModalConfirm}
              >
                Supprimer mon compte
              </button>
            </li>
          )}
        </ul>
        <div className="flex items-center justify-between py-6 px-5">
          {isAuthenticated ? (
            <ButtonPrimary className="bg-red-500 hover:bg-red-500" onClick={handleLogout}>
              Déconnexion
            </ButtonPrimary>
          ) : (
            <ButtonPrimary href="/login">Connexion</ButtonPrimary>
          )}

          <LangDropdown
            className="flex"
            panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 right-3 bottom-full sm:px-0"
          />
        </div>
      </div>

      {/* Popup de confirmation de suppression */}
      <Transition appear show={isOpenModalConfirm} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModalConfirm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Confirmer la suppression
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Êtes-vous sûr de vouloir supprimer définitivement votre compte ?
                      Cette action est irréversible.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end space-x-3">
                    <ButtonClose onClick={closeModalConfirm}>
                      Annuler
                    </ButtonClose>
                    <ButtonPrimary className="bg-red-500 hover:bg-red-500" onClick={handleDeleteAccount}>
                      Supprimer
                    </ButtonPrimary>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default NavMobile;

"use client";
import React, { useState } from "react";
import LocationInput from "../LocationInput";
import ArrivalInput from "../ArrivalInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "../../type";
import converSelectedDateToString from "@/utils/converSelectedDateToString";
import { useSearchForm } from "../../(HeroSearchForm)/SearchFormContext";
import InfoNameInput from "../../(HeroSearchForm)/InfoNameInput";

const FlightSearchForm = () => {
  //
  
  const [fieldNameShow, setFieldNameShow] = useState<"locationPickup" | "locationDropoff" | "dates" | "guests" | "general" | "name" | "phone" | "email" | "">("locationPickup");
  //
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(
    new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const { model, setModel, type_flight, setTypeFlight } = useSearchForm();

  const [dropOffLocationType, setDropOffLocationType] = useState<
    "Aller-retour" | "Aller simple" | ""
  >("Aller-retour");
  const [flightClassState, setFlightClassState] = useState("Hélicoptère");

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span className="text-neutral-400">Lieu de départ</span>
            <span>{locationInputPickUp || "Lieu de départ"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Départ"
          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span className="text-neutral-400">Lieu d'arrivée</span>
            <span>{locationInputDropOff || "Lieu d'arrivée"}</span>
          </button>
        ) : (
          <ArrivalInput
            headingText="Arrivée"
            desc="Choisissez votre point d'arrivée"
          />
          
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">Quand</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Ajouter des dates"}
            </span>
          </button>
        ) : (
          <DatesRangeInput selectsRange={dropOffLocationType === "Aller-retour"} href="/listing-flights-private"/>
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Type de vol</span>
            <span>{`${dropOffLocationType}, ${flightClassState}`}</span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Type de vol
            </span>
            <div className="relative mt-5">
              <div className="flex space-x-2">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "Aller-retour"
                      ? "bg-black shadow-black/10 shadow-lg text-white"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => {
                    setDropOffLocationType("Aller-retour")
                    setTypeFlight("Aller-retour");
                  }}
                >
                  Aller-retour
                </div>
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "Aller simple"
                      ? "bg-black text-white shadow-black/10 shadow-lg"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => {
                    setDropOffLocationType("Aller simple")
                    setTypeFlight("Aller simple");
                  }}
                >
                  Aller simple
                </div>
              </div>

              <div className="mt-6">
                <label className="text-base font-semibold" htmlFor="">
                  Mode de transport
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderRadio("class", "Hélicoptère", "Hélicoptère")}
                  {renderRadio("class", "Jet privé", "Jet privé")}
                  {renderRadio("class", "Avion privé", "Avion privé")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={flightClassState === label}
          id={id + name}
          name={name}
          onChange={() => {
            setFlightClassState(label);
            setModel(label);
          }}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
      guestSelected += `${guest} guests`;
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Passagers</span>
            <span>{guestSelected || `Ajouter des passagers`}</span>
          </button>
        ) : (
          <GuestsInput />
        )}
      </div>
    );
  };

  const renderInputField = (
    label: string,
    value: string,
    onClick: () => void,
    active: boolean,
    children: React.ReactNode
  ) => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        active
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      {!active ? (
        <button
          className="w-full flex justify-between text-sm font-medium p-4"
          onClick={onClick}
        >
          <span className="text-neutral-400">{label}</span>
          <span>{value}</span>
        </button>
      ) : (
        children
      )}
    </div>
  );

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {/*  */}
        {renderInputLocationDropoff()}
        {/*  */}
        {renderGenerals()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
        {renderInputField(
        "Prénom, Nom",
        "",
        () => setFieldNameShow("name"),
        fieldNameShow === "name",
        <InfoNameInput 
        placeHolder="Prénom, Nom"
        desc="Renseignez votre nom et prénom"
        className="flex-1"
        type="name"
        />
      )}
      {renderInputField(
        "N° de téléphone",
        "",
        () => setFieldNameShow("phone"),
        fieldNameShow === "phone",
        <InfoNameInput 
        placeHolder="N° de téléphone"
        desc="Renseignez votre n° de téléphone"
        className="flex-1"
        type="phone"
        />
      )}

      {renderInputField(
        "Adresse e-mail",
        "",
        () => setFieldNameShow("email"),
        fieldNameShow === "email",
        <InfoNameInput 
        placeHolder="Adresse e-mail"
        desc="Renseignez votre e-mail"
        className="flex-1"
        type="email"
        />
      )}
      </div>
    </div>
  );
};

export default FlightSearchForm;

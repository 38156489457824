"use client";

import { PathName } from "@/routers/types";
import { useRouter } from "next/navigation";
import React, { FC } from "react";
import { useSearchForm } from "../(HeroSearchForm)/SearchFormContext";
import { Route } from "next";

interface Props {
  className?: string;
  href?: PathName;
  onClick?: () => void;
}

const ButtonSubmit: FC<Props> = ({ 
  className = "",
  href = "/listing-product-map",
  onClick
}) => {
  const router = useRouter();
  const { lat, lng, totalGuests, duration, category, eventtype, date, location, name, email, phone, arrival_lat, arrival_lng, type_flight, model, date_retour, location_arrival } = useSearchForm();

  const handleSubmit = () => {
    if (lat === null || lng === null) {
      alert("Veuillez sélectionner un lieu de départ");
      return;
    }

    let minDuration = 0;
    let maxDuration = 120;

    switch (duration) {
      case '20 min':
        maxDuration = 120;
        break;
      case '30 min':
        minDuration = 0;
        maxDuration = 120;
        break;
      case '1h et plus':
        minDuration = 0;
        break;
    }

    let url;
    if (href === "/listing-flights") {
      if (arrival_lat) {
        url = `${href}?location=${location}&location_arrival=${location_arrival}&lat=${lat}&lng=${lng}&arrival_lat=${arrival_lat}&arrival_lng=${arrival_lng}&type_flight=${type_flight}&model=${model}&date=${date}&datearrivee=${date_retour}&name=${name}&email=${email}&phone=${phone}&passengers=${totalGuests}`;
      } else {
        url = `${href}?location=${location}&lat=${lat}&lng=${lng}&date=${date}&eventtype=${eventtype}&name=${name}&email=${email}&phone=${phone}&passengers=${totalGuests}`;
      }
    } else {
      url = `${href}?minPrice=0&maxPrice=1000&minDuration=${minDuration}&maxDuration=${maxDuration}&maxGuests=${totalGuests}&lat=${lat}&lng=${lng}&category=${encodeURIComponent(category)}&location=${location}`;
    }

    router.push(url as Route);

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      onClick={handleSubmit}
      type="button"
      className={`flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none ${className} relative z-20`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
      <span className="ml-2">Rechercher</span>
    </button>
  );
};

export default ButtonSubmit;
"use client";
import React, { useState, useEffect } from 'react';
import { setCookie, getCookie } from 'cookies-next';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    // Fonction pour détecter si l'appareil est un ordinateur de bureau en fonction de la taille, du système d'exploitation, et du user agent (mobile app inclus)
    const checkIfDesktop = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isIPad = /ipad|macintosh/.test(userAgent) && 'ontouchend' in document;
      const isMobileApp = userAgent.includes('mobile') || userAgent.includes('mobileios') || userAgent.includes('mobileandroid'); // Vérifie si le user agent contient 'mobileapp'
      
      return window.innerWidth >= 1024 && !isIPad && !isMobileApp; // Considérer comme desktop si largeur >= 1024px, ce n'est pas un iPad, et ce n'est pas une app mobile
    };

    const handleResize = () => {
      setIsDesktop(checkIfDesktop());
    };

    // Initialiser la détection
    setIsDesktop(checkIfDesktop());

    const consentGiven = getCookie('cookie-consent');
    if (!consentGiven && checkIfDesktop()) {
      setShowConsent(true);
      
    }

    // Ajouter un event listener pour surveiller les changements de taille d'écran
    window.addEventListener('resize', handleResize);

    // Nettoyage de l'event listener au démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const acceptCookies = () => {
    setCookie('cookie-consent', 'true', { maxAge: 60 * 60 * 24 * 365 }); // 1 an
    setShowConsent(false);
  };

  // Si le consentement est déjà donné ou si l'appareil n'est pas un PC de bureau, ne rien afficher
  if (!showConsent || !isDesktop) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-100 p-4 shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center">
        <p className="text-sm">
          Ce site utilise des cookies pour améliorer l'expérience utilisateur.
        </p>
        <button
          onClick={acceptCookies}
          className="bg-[#2563eb] rounded-full hover:bg-blue-700 text-white font-bold py-2 px-4"
        >
          Accepter
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;

import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const DatePickerCustomHeaderOneMonth = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  // Function to capitalize the first letter
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Get month and year in French
  const monthYear = capitalizeFirstLetter(
    date.toLocaleString("fr-FR", {
      month: "long",
      year: "numeric",
    })
  );

  return (
    <div className="flex items-center justify-between px-2 py-2">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
        className={`p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 ${
          prevMonthButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        aria-label="Mois précédent"
      >
        <ChevronLeftIcon className="w-5 h-5" />
      </button>
      <span className="text-lg font-semibold">
        {monthYear}
      </span>
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
        className={`p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 ${
          nextMonthButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        aria-label="Mois suivant"
      >
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

export default DatePickerCustomHeaderOneMonth;